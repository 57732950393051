const url = process.env.VUE_APP_FRONTEND_URL;

const oauth = {
  // github: {
  //   url: "https://github.com/login/oauth/authorize",
  //   params: {
  //     client_id: process.env.GITHUB_KEY,
  //     redirect_uri: `${url}/auth/github/callback`,
  //     login: "",
  //     scope: "user:email",
  //     state: "eworifjeovivoiej",
  //   },
  // },
  "google-oauth2": {
    url: "https://accounts.google.com/o/oauth2/v2/auth",
    params: {
      client_id: process.env.VUE_APP_AUTH_GOOGLE_CLIENT_ID,
      response_type: "code",
      scope: "email profile",
      redirect_uri: `${url}/google-connect/`,
      access_type: "offline",
      prompt: "consent",
    },
  },
  // facebook: {
  //   url: "https://www.facebook.com/v5.0/dialog/oauth",
  //   params: {
  //     client_id: process.env.FACEBOOK_KEY,
  //     redirect_uri: `${url}/auth/facebook/callback`,
  //     state: "eworifjeovivoiej", // TODO: change these
  //     scope: "email",
  //   },
  // },
};

export default oauth;
