<template>
  <v-app>
    <v-app-bar
      app
      color="transparent"
      light
      elevation="0"
      hide-on-scroll
      :scroll-threshold="50"
    >
      <router-link to="/" class="text-decoration-none">
        <div class="d-flex align-center app-logo">
            <v-img
              alt="jutroMecz.pl Logo"
              class="shrink mr-2"
              contain
              src="/static/images/logo.svg"
              transition="scale-transition"
              width="40"
            />
            <span class="text-decoration-none font-weight-bold">JutroMecz.pl</span>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-item-group class="navbar-btns">

        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed plain
               to="/support" color="">
          Wsparcie
        </v-btn>
        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed plain
               to="/#how-it-works" color=""
               @click="$vuetify.goTo('#how-it-works', {})">
          Jak to działa
        </v-btn>
        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed plain
               to="/user" color=""
               v-show="this.isAuthenticated">
          Konto
        </v-btn>
        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed plain
               to="/register" color=""
               v-show="!this.isAuthenticated">
          Załóż konto
        </v-btn>
        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed
               to="/login" color="primary"
               v-show="!this.isAuthenticated">
          Zaloguj się
        </v-btn>
        <v-btn class="hidden-sm-and-down mx-1"
               rounded depressed
               to="/calendars" color="primary"
               v-show="this.isAuthenticated">
          Moje kalendarze
        </v-btn>
        <v-btn rounded depressed plain icon x-small
               title="jutroMecz.pl na Twitterze"
               color="primary"
               class="hidden-sm-and-down ml-5"
               href="https://twitter.com/jutromecz"
               target="_blank">
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </v-item-group>

      <v-app-bar-nav-icon @click="navigationDrawer = true" class="hidden-md-and-up">
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="navigationDrawer"
                         fixed temporary right touchless
                         class="hidden-md-and-up">
      <v-list nav flat>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item to="/calendars"
                       v-show="this.isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Moje kalendarze</v-list-item-title>
          </v-list-item>

          <v-list-item to="/user"
                       v-show="this.isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Konto</v-list-item-title>
          </v-list-item>

          <v-list-item to="/register"
                       v-show="!this.isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Załóż konto</v-list-item-title>
          </v-list-item>

          <v-list-item to="/login"
                       v-show="!this.isAuthenticated">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Zaloguj się</v-list-item-title>
          </v-list-item>

          <v-list-item to="/#how-it-works"
                       @click="$vuetify.goTo('#how-it-works', {})">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Jak to działa</v-list-item-title>
          </v-list-item>

          <v-list-item to="/support">
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Wsparcie</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer padless class="page-footer" color="white">
      <div style="width: 100%; height: 5vh;" class="page-footer-vawe px-0"></div>
      <div style="width: 100%; background-color: #F8F8F9">
        <v-row class="page-size">
            <v-col class="d-flex pb-lg-10 pb-5" cols="12" lg="2">
              <v-spacer></v-spacer>
              <v-btn text href="/" class="btn-no-caps">
                <strong>JutroMecz.pl</strong>
              </v-btn>
              <v-spacer></v-spacer>
            </v-col>
            <v-col class="d-flex align-center justify-center pb-lg-10 pb-5" cols="12" lg="2">
              <v-btn text href="/contact">Kontakt</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center pb-lg-10 pb-5" cols="12" lg="2">
              <v-btn text href="/support">Wsparcie</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center pb-lg-7" cols="12" lg="2">
              <v-btn text href="/terms-and-conditions">Regulamin</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center pb-lg-7" cols="12" lg="2">
              <v-btn text href="/rodo">Informacje o RODO</v-btn>
            </v-col>
          <v-col class="d-flex align-center justify-center pb-lg-7" cols="12" lg="2">
            <v-btn text href="/privacy-policy">Polityka prywatności</v-btn>
          </v-col>
        </v-row>
        <v-row  class="page-size">
          <v-col cols="12" lg="12" style="font-size: 12px;" class="font-weight-bold">
            Dodaj terminarz wybranego zespołu lub rozgrywek do swojego kalendarza (Google&nbsp;Calendar). Wydarzenia zostaną zintegrowane z Twoim kalendarzem oraz będą automatycznie aktualizowane.
          </v-col>
        </v-row>
        <v-row  class="page-size">
          <v-col cols="12" lg="12" style="font-size: 12px;" class="links-without-decoration">
            <span class="font-weight-bold">Kalendarz piłkarski, sezon 2023/2024: </span>
            <router-link to="/@euro-2024">EURO 2024</router-link>・
            <router-link to="/@copa-america">Copa America 2024</router-link>・
            <router-link to="/@champions-league">Liga Mistrzów UEFA</router-link>・
            <router-link to="/@real-madrid">Real Madrid</router-link>・
            <router-link to="/@barcelona">Barcelona</router-link>・
            <router-link to="/@manchester-united">Manchester United</router-link>・
            <router-link to="/@ac-milan">AC Milan</router-link>・
            <router-link to="/@inter">Inter Milan</router-link>・
            <router-link to="/@arsenal">Arsenal</router-link>・
            <router-link to="/@juventus">Juventus</router-link>・
            <router-link to="/@liverpool">Liverpool</router-link>・
            <router-link to="/@chelsea">Chelsea</router-link>・
            <router-link to="/@ajax">Ajax</router-link>・
            <router-link to="/@benfica">Benfica</router-link>・
            <router-link to="/@bayern-munich">Bayern Munich</router-link>・
            <router-link to="/@boca-juniors">Boca Juniors</router-link>・
            <router-link to="/@lyon">Olympique Lyonnais</router-link>・
            <router-link to="/@rangers">Rangers FC</router-link>・
            <router-link to="/@celtic">Celtic FC</router-link>・
            <router-link to="/@paris-saint-germain">Paris Saint-Germain</router-link>・
            <router-link to="/@marseille">Olympique De Marseille</router-link>・
            <router-link to="/@flamengo">Flamengo</router-link>・
            <router-link to="/@fc-porto">FC Porto</router-link>・
            <router-link to="/@borussia-dortmund">Borussia Dortmund</router-link>・
            <router-link to="/@sporting-cp">Sporting CP</router-link>・
            <router-link to="/@atletico-madrid">Atletico Madrid</router-link>・
            <router-link to="/@manchester-city">Manchester City</router-link>・
            <router-link to="/@club-america">Club América</router-link>・
            <router-link to="/@palmeiras">Palmeiras</router-link>・
            <router-link to="/@river-plate">River Plate</router-link>・
            <router-link to="/@flamengo">Flamengo</router-link>・
            <router-link to="/@legia-warszawa">Legia Warszawa</router-link>・
            <router-link to="/@lech-poznan">Lech Poznań</router-link>・
            <router-link to="/@slask-wroclaw">Śląsk Wrocław</router-link>・
            <router-link to="/@wisla-krakow">Wisła Kraków</router-link>・
            <router-link to="/@al-ahly">Al Ahly</router-link>・
            <router-link to="/@wydad-ac">Wydad AC</router-link>・
            <router-link to="/@raja-casablanca">Raja Casablanca</router-link>・
            <router-link to="/@al-hilal-saudi-fc">Al-Hilal</router-link>・
            <router-link to="/@al-nassr">Al-Nassr</router-link>・
            <router-link to="/@al-shabab">Al Shabab</router-link>・
            <router-link to="/@brazil">Brazylia</router-link>・
            <router-link to="/@argentina">Argentyna</router-link>・
            <router-link to="/@france">Francja</router-link>・
            <router-link to="/@poland">Polska</router-link>・
            <router-link to="/@germany">Niemcy</router-link>・
            <router-link to="/@caf-champions-league">Afrykańska Liga Mistrzów CAF</router-link>・
            <router-link to="/@fifa-club-world-cup">Klubowe Mistrzostwa Świata FIFA</router-link>・
            <router-link to="/@la-liga">La Liga EA Sports</router-link>・
            <router-link to="/@premier-league">Premier League</router-link>・
            <router-link to="/@bundesliga">Bundesliga</router-link>・
            <router-link to="/@serie-a">Serie A</router-link>・
            <router-link to="/@ligue-1">Ligue 1 Uber Eats</router-link>・
            <router-link to="/@ekstraklasa">PKO BP Ekstraklasa</router-link>
          </v-col>
        </v-row>
      </div>

    </v-footer>

    <cookie-consent class="d-flex flex-column align-center text-center pa-4 my-2">
      <template v-slot:message>
        Ta strona używa plików cookies w celach statystycznych, reklamowych oraz funkcjonalnych.
        <a href="/privacy-policy"
           class="mx-3">
          Polityka Prywatności
        </a>
      </template>
      <template v-slot:button>
        <v-btn primary rounded
               class="mx-3"
               color="primary">
          Akceptuję
        </v-btn>
      </template>
    </cookie-consent>
  </v-app>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {USER_REQUEST} from "@/store/action-types";
import { Scroll } from 'vuetify/lib/directives/scroll'

export default {
  name: 'App',
  components: {},
  metaInfo: {
    title: 'Mecze ulubionych drużyn w Twoim kalendarzu',
    titleTemplate: '%s | JutroMecz.pl',
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'Dodaj ulubione drużyny do swojego kalendarza, a już nigdy nie zapomnisz o nadchodzącym meczu! | Otrzymuj przypomnienia o nadchodzących meczach wybranych zespołów' }
    ],
  },
  directives: {
    Scroll,
  },
  data() {
    return {
      navigationDrawer: false,
      snackbar: true,
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authMessage", "authStatus", "hasMessage", "userData"]),
    ...mapState({
      authLoading: state => state.authStatus === 'loading',
    }),
    items() {
       if (this.userData === undefined && this.isAuthenticated) {
        this.$store.dispatch(USER_REQUEST)
      }
      return ''
    },
  },
  methods: {
    onScroll() {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/9993
      const { appBar } = this.$refs
      if (appBar.currentScroll < appBar.currentThreshold) {
        appBar.isActive = true
      }
    },
  }
};
</script>

<style lang="scss">
  @import "public/static/variables.scss";

  .app-logo span {
    color: $main-green !important;
  }

  .side-nav-marked-item, .side-nav-marked-item.v-list-item--active {
    background-color:  $main-purple !important;
    .v-list-item__title {
      color: white !important;
    }
    .v-list-item__icon i {
      color: white !important;
    }
  }
</style>

<style lang="scss">
@import "vue-cookieconsent-component/src/scss/cookie-consent";
@import "vue-cookieconsent-component/src/scss/cookie-consent-bottom";

.cookie-consent {
  background: #F2F4F5;
  width: fit-content;
  border-radius: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Roboto');

$font-family: 'Poppins';

.v-application {
  font-family: $font-family, sans-serif !important;
}

.navbar-btns .v-btn {
  text-transform: initial;
  font-weight: 600;
}

.google-btn {
  background-color: white !important;
  border: 1px solid black;
  .v-btn__content {
    font-family: Roboto Medium, sans-serif !important;
    color: black;
    display: flex;
    justify-content: center;
    img {
      margin: 8px;
    }
  }
  .v-btn__loader {
    color: black;
  }
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.v-application
  .v-app-bar--is-scrolled.transparent
    background-color: white !important

.homepage
  .v-main
    padding: 0 !important
  .v-application
    .v-app-bar--is-scrolled.transparent
      background-color: #4a4a4a !important
    .app-logo span
      color: white !important
    .navbar-btns a, .v-app-bar__nav-icon
      color: white !important

.welcome-row
  background-image: url("../public/static/images/stadium_photo4.jpg")
  background-position: center
  background-repeat: no-repeat
  background-origin: border-box
  background-size: cover
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.7)
  height: 40vh
  max-height: 768px
  min-height: 350px

@media (max-width: 340px)
  .welcome-row h1
    font-size: 1.5rem
@media (max-width: 260px)
  .welcome-row h1
    font-size: 1.25rem

@media #{map-get($display-breakpoints, 'sm-and-down')}
  .welcome-row
    height: 30vh

.v-card__title
  word-break: break-word !important

.full-page-form
  form
    .v-card
      padding: 12px 40px
      @media #{map-get($display-breakpoints, 'sm-and-down')}
        padding: 4px
      .v-card__title
        font-style: normal
        font-weight: 700
        font-size: 24px
        line-height: 36px
        letter-spacing: 0.02em
      .v-card__subtitle
        font-style: normal
        font-weight: 300
        font-size: 15px
        line-height: 22px
        margin-bottom: 24px

.btn-no-caps
  text-transform: initial !important

.terms-page
  h1
    font-style: normal
    font-weight: 700
    font-size: 46px
    line-height: 144%
    text-align: center
    padding-bottom: 20px
  h2
    font-style: normal
    font-weight: 600
    font-size: 32px
    line-height: 48px
    text-align: center
    padding: 12px 0

.v-footer
  .links-without-decoration
    a
      text-decoration: none
      color: inherit

</style>

<style>


.page-size {
  max-width: 1920px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
header .v-toolbar__content {
  max-width: 1920px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.v-footer {
  display: block !important;
}

.welcome-row-container {
  background-image: url("../public/static/images/Vector17.svg") !important;
  background-size: 100% 100%;
  background-position: top;
}
.welcome-row-container .welcome-row {
  background-image: url("../public/static/images/Vector18.svg") !important;
  background-size: 100% 100%;
  background-position-y: 24px;
}

.how-it-works-vawe-container {
  background-image: url("../public/static/images/Vector19.svg") !important;
  background-size: 100% 100%;
  background-position: bottom;
}
.how-it-works-row {
  background-image: linear-gradient(270.04deg, #06004D 0.02%, #363992 99.96%);
}
.how-it-works-bottom-vawe-container {
  background-image: url("../public/static/images/Vector20.svg") !important;
  background-size: 100% 100%;
  background-position: top;
}

.how-it-works-row .steps-wide {
  background-image: url("../public/static/images/stripped vawe.svg") !important;
  background-position-x: 30px;
  background-position-y: center;
  background-size: 70%;
}
.how-it-works-row .steps-wide .step {
  width: 140px;
  height: 140px;
  background-image: url("../public/static/images/hug.svg") !important;
  background-size: 100% 100%;
  background-position: center;
}
.how-it-works-row .steps-wide .step-1 {
}
.how-it-works-row .steps-wide .step-2 {
  transform: rotate(45deg);
}
.how-it-works-row .steps-wide .step-2 > img {
  transform: rotate(-45deg);
}
.how-it-works-row .steps-wide .step-3 {
  transform: rotate(-35deg);

}
.how-it-works-row .steps-wide .step-3 > img {
  transform: rotate(35deg);
}

.page-footer-vawe {
  background-image: url("../public/static/images/Vector21.svg") !important;
  background-size: 100% 100%;
  background-position: bottom;
  height: 100px;
}
</style>
