export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const PERMISSIONS_REQUESTS = 'PERMISSIONS_REQUESTS'
export const USER_REQUEST = "USER_REQUEST";

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST'

export const SET_PROJECT = 'SET_PROJECT'
export const SET_PIPELINE = 'SET_PIPELINE'
export const CLEAR_PROJECT = 'CLEAR_PROJECT'
export const CLEAR_PIPELINE = 'CLEAR_PIPELINE'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
export const SET_LAST_ROUTE_IN_LOCAL_STORAGE = 'SET_LAST_ROUTE_IN_LOCAL_STORAGE'
