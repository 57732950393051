export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const PERMISSIONS_REQUESTS = 'PERMISSIONS_REQUESTS'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR'

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST'

export const SET_PROJECT = 'SET_PROJECT'
export const SET_PIPELINE = 'SET_PIPELINE'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const CLEAR_PROJECT = 'CLEAR_PROJECT'
export const CLEAR_PIPELINE = 'CLEAR_PIPELINE'
export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS'
export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD'
export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
