import * as mutation_types from "@/store/mutation-types"
import * as action_types from "@/store/action-types"
import axios from "axios";

const LOCAL_STORAGE_KEY = 'accessToken'
const LAST_ROUTE_LOCAL_STORAGE_KEY = 'lastRoute'

const state = () => ({
    token: localStorage.getItem(LOCAL_STORAGE_KEY) || '',
    status: '',
    message: '',
    user: undefined,
})

const getters = {
    authStatus: state => state.status,
    isAuthenticated: state => Boolean(state.token),
    authMessage: state => state.message,
    hasMessage: state => Boolean(state.message),
    userData: state => state.user
}

const actions = {
    [action_types.LOGIN_REQUEST]: ({commit, dispatch}, credentials) =>
        new Promise((resolve, reject) => {
            commit(mutation_types.LOGIN_REQUEST)

            axios.post('/auth/login/', credentials)
                .then(response => {
                    const authToken = response.data['key']
                    localStorage.setItem(LOCAL_STORAGE_KEY, authToken)
                    commit(mutation_types.LOGIN_SUCCESS, authToken);
                    dispatch(action_types.USER_REQUEST);
                    resolve(authToken);
                })
                .catch(err => {
                    commit(mutation_types.LOGIN_ERROR)
                    localStorage.removeItem(LOCAL_STORAGE_KEY)
                    reject(err)
                })
        }),
    [action_types.LOGOUT_REQUEST]: ({commit}) =>
        new Promise((resolve => {
            commit(mutation_types.LOGOUT_REQUEST)
            commit(mutation_types.CLEAR_USER)
            localStorage.removeItem(LOCAL_STORAGE_KEY)
            localStorage.removeItem(LAST_ROUTE_LOCAL_STORAGE_KEY)
            resolve();
        })),
    [action_types.USER_REQUEST]: ({commit}) =>
        new Promise((resolve, reject) => {
            axios.get('/auth/user/')
                .then(response => {
                    let user = response.data
                    commit(mutation_types.SET_USER, user)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        }),
    [action_types.REGISTER_REQUEST]: ({commit}, credentials) =>
        new Promise((resolve, reject) => {
            axios.post('/auth/registration/', credentials)
                .then(response => {
                    let message = response.data.detail
                    commit(mutation_types.REGISTER_REQUEST, message)
                    resolve(response)
                })
                .catch(err => {
                    commit(mutation_types.LOGIN_ERROR)
                    reject(err)
                })
        }),
    [action_types.VERIFY_EMAIL]: ({commit}, params) =>
        new Promise((resolve, reject) => {
            axios.post('/auth/account-confirm-email/', params)
                .then(response => {
                    commit(mutation_types.VERIFY_EMAIL)
                    resolve(response)
                }).catch(err => {
                    let message = err.response?.data?.detail || "Wystąpił błąd. Spróbuj ponownie."
                    commit(mutation_types.VERIFY_EMAIL_ERROR, message)
                    reject(err)
                })
        }),
    [action_types.GOOGLE_LOGIN_REQUEST]: ({commit, dispatch}, code) =>
        new Promise((resolve, reject) => {
            commit(mutation_types.LOGIN_REQUEST)

            axios.post('/auth/google/', code)
                .then(response => {
                    const authToken = response.data['key']
                    localStorage.setItem(LOCAL_STORAGE_KEY, authToken)
                    commit(mutation_types.LOGIN_SUCCESS, authToken);
                    dispatch(action_types.USER_REQUEST);
                    resolve(authToken);
                })
                .catch(err => {
                    commit(mutation_types.LOGIN_ERROR)
                    localStorage.removeItem(LOCAL_STORAGE_KEY)
                    reject(err)
                })
        }),
}
const mutations = {
    [mutation_types.LOGIN_REQUEST]: state => {
        state.status = 'loading'
    },
    [mutation_types.LOGIN_SUCCESS]: (state, authToken) => {
        state.status = 'success'
        state.token = authToken
    },
    [mutation_types.LOGIN_ERROR]: state => {
        state.status = 'error'
    },
    [mutation_types.LOGOUT_REQUEST]: state => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        state.token = "";
        state.status = ''
    },
    [mutation_types.SET_USER]: (state, user) => {
        state.user = user || undefined
    },
    [mutation_types.CLEAR_USER]: state => {
        state.user = undefined
    },
    [mutation_types.REGISTER_REQUEST]: (state, message) => {
        state.status = 'success'
        state.message = message
    },
    [mutation_types.VERIFY_EMAIL]: (state) => {
        state.message = "Potwierdzono email. Możesz się zalogować."
    },
    [mutation_types.VERIFY_EMAIL_ERROR]: (state, message) => {
        state.status = 'error'
        state.message = message
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
