import VueRouter from "vue-router";
import Vue from "vue";
import store from "@/store";
import * as action_types from "@/store/action-types";

Vue.use(VueRouter)

const logout = (to, from, next) => {
    store.dispatch(action_types.LOGOUT_REQUEST)
        .then(() => next('/'))
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated)
        next()
    else if (store.state.lastRoute)
        next(store.state.lastRoute.path)
    else
        next('/')
}

const isAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
    } else {
        store.dispatch(action_types.SET_LAST_ROUTE, to).then(() => {
            next('/login')
        })
    }
}
// const verifyEmailAddress = (to, from, next) => {
//     if (store.getters.isAuthenticated)
//         next("/")
//     store.dispatch(action_types.VERIFY_EMAIL, {key: to.params.key})
//         .then(() => next("/"))
//         .catch(err => {
//             console.log(err)
//             if (err.response.status === 404) {
//                 next("/")
//             }
//         })
// }

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/logout",
            beforeEnter: logout,
        },
        {
            path: "/login",
            component: () => import("@/components/auth/LoginForm"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/register",
            component: () => import("@/components/auth/RegisterForm"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/confirm-email/:key",
            component: () => import("@/components/auth/VerifyEmail"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/resend-email/",
            component: () => import("@/components/auth/ResendVerificationEmail"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/reset-password",
            component: () => import("@/components/auth/ResetPasswordRequestForm"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/reset-password-confirm/:uid/:token",
            component: () => import("@/components/auth/ResetPasswordForm"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/google-connect",
            component: () => import("@/components/auth/GoogleConnect"),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: "/google-authorize",
            component: () => import("@/components/auth/GoogleAuthorizeCalendar"),
            beforeEnter: isAuthenticated,
        },
        {
            path: "/user",
            component: {render: c => c('router-view')},
            beforeEnter: isAuthenticated,
            children :[
                {
                    path: "",
                    component: () => import("@/components/account/AccountView"),
                },
                {
                    path: "change-password",
                    component: () => import("@/components/auth/ChangePasswordForm"),
                }
            ]
        },
        {
            path: "/calendars",
            component: () => import("@/components/account/CalendarsView"),
            beforeEnter: isAuthenticated,
        },
        {
            path: "/terms-and-conditions",
            component: () => import("@/components/terms/TermsAndConditions"),
        },
        {
            path: "/privacy-policy",
            component: () => import("@/components/terms/PrivacyPolicy"),
        },
        {
            path: "/rodo",
            component: () => import("@/components/terms/RodoInfo"),
        },
        {
            path: "/support",
            component: () => import("@/components/pages/SupportProjectView")
        },
        {
            path: "/contact",
            component: () => import("@/components/pages/ContactView")
        },
        {
            path: "/",
            component: () => import("@/components/HomeView"),
            children: [
                {
                    path: "@:slug",
                    component: () => import("@/components/elements/ClubCardDialog")
                },
                {
                    path: "your-calendar",
                    component: () => import("@/components/elements/CustomCalendarDialog")
                },
            ]
        },
        {path: '*', component: () => import("@/components/pages/Error404")}
    ]
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && to.path !== '/register' && to.path !== '/google-connect' && to.path !== '/google-connect/' && to.path !== '/logout') {
      const currentRoute = to.path;
      localStorage.setItem('lastRoute', currentRoute);
  }
  next()
})

export default router;
