import * as mutation_types from "@/store/mutation-types"
import * as action_types from "@/store/action-types"

const LAST_ROUTE_LOCAL_STORAGE_KEY = 'lastRoute'

const state = () => ({
    lastRoute: undefined,
    lastRouteInLocalStorage: localStorage.getItem(LAST_ROUTE_LOCAL_STORAGE_KEY) || '',
})
const getters = {
    hasLastRoute: state => state.lastRoute !== undefined,
    hasLastRouteInLocalStorage: state => state.lastRouteInLocalStorage !== undefined,
}
const actions = {
    [action_types.SET_LAST_ROUTE]: ({commit}, lastRoute) =>
        new Promise((resolve => {
            commit(mutation_types.SET_LAST_ROUTE, lastRoute)
            resolve()
        })),
    [action_types.SET_LAST_ROUTE_IN_LOCAL_STORAGE]: ({commit}, lastRoute) =>
        new Promise((resolve => {
            localStorage.setItem(LAST_ROUTE_LOCAL_STORAGE_KEY, lastRoute.path)
            commit(mutation_types.SET_LAST_ROUTE, lastRoute)
            resolve()
        })),
}
const mutations = {
    [mutation_types.SET_LAST_ROUTE]: (state, lastRoute) => state.lastRoute = lastRoute,
}

export default {
    state,
    getters,
    actions,
    mutations
};
