import Vue from 'vue'
import App from './App.vue'
import {configureAxios} from "./axios-config";
import vuetify from './plugins/vuetify'
import router from "../router";
import store from './store'
import vuemeta from './plugins/vuemeta'
import VueHorizontal from "vue-horizontal";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import cookieconsent from 'vue-cookieconsent-component'

Vue.config.productionTip = false

configureAxios()

Vue.component(VueHorizontal)
Vue.component('cookie-consent', cookieconsent)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "jutromecz.pl", /^\//],
    }),
  ],
  tracesSampleRate: 0.1,
});

new Vue({
  vuetify,
  router,
  store,
  vuemeta,
  render: h => h(App)
}).$mount('#app')
